$fuel-back: #ef7b0b;
$fuel-hover: #dc6e12;
$fuel-back-light: #ffa75c;
$fuel-back-light-hover: #5999c5;
$old-color: #474b5a;
$old-color-light: #1288da;
$fuel-color: #474b5a;
$fuel-color-light: #ffa75c;
$sidebar-color: #474b5a;
$pass-color: #509ee3;
$pass-color-light: #5999c5;

@import "react-big-calendar/lib/sass/styles";
@import "../../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
@import "./coreUI.scss";
@import "./utils/simple-line-icons.css";
// @import './utils/_credit_card.scss';
@import "./utils/_checkbox.scss";
@import "./utils/_utils.scss";
@import "./utils/_toast.css";
@import "./utils/_datepicker.scss";
@import "./utils/_datepicker.scss";

a {
  outline: none !important;
}

.btn-fuel {
  color: #fff;
  background-color: $fuel-color;
  border-color: $fuel-color;
}

.bg-theme-grey {
  background: rgba(71, 75, 90, 0.17);
}

.btn-fuel:hover,
.btn-fuel:focus,
.btn-fuel.focus {
  color: #fff;
  background-color: $pass-color-light;
  border-color: $pass-color-light;
}

.app-header {
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
  -webkit-clip-path: inset(0 0 -1000px 0);
  clip-path: inset(0 0 -1000px 0);
  background: #fff;
}

.app-header .navbar-brand {
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

.modal-xxl {
  min-width: 80% !important;
}

.modal-xxxl {
  min-width: 95% !important;
}

.modal-body {
  z-index: 0;
}

.sidebar {
  border: 0;
  background: $sidebar-color;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
}

.sidebar .nav-title {
  color: rgba(255, 255, 255, 0.6);
}

.sidebar .nav-link.active,
.sidebar .old-bo-link {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.sidebar .nav-link,
.sidebar .old-bo-link {
  color: rgba(255, 255, 255, 0.8);
}

.sidebar .nav-link:hover,
.sidebar .old-bo-link {
  color: #fff;
  background: $fuel-back-light-hover;
}

.sidebar .sidebar-header {
  background: $sidebar-color;
}

.avatar-first-letter {
  border-radius: 50%;
  padding: 0.5rem;
  width: 60px;
  height: 60px;
  align-items: center;
  // border: 1px solid;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: auto;
  color: #ffffff;
  font-size: 1.6rem;
  justify-content: center;
  background: $pass-color;
}

.navbar-brand .brand-image {
  margin-left: auto;
  margin-right: auto;
  max-height: 40px;
}

.login-image {
  margin-left: auto;
  margin-right: auto;
  max-height: 150px;
}

.navbar-brand {
  padding: 0.58rem 0.1rem;
}

.nav-dropdown-items > .nav-item > .nav-link {
  font-size: 13px;
  padding-left: 2.5rem;
}

.nav-dropdown-toggle {
  cursor: pointer;
}

.d-cursor {
  cursor: pointer;
}

.tab-content {
  margin-top: -1px;
  background: #fff;
  border-top: 1px solid #d1d4d7;
}

.chart-wrapper .tab-content {
  border: none;
  border-top: 1px solid #d1d4d7 !important;
}

.text-fuel {
  color: $fuel-color;
}

.text-fuel-light {
  color: $fuel-color-light;
}

.text-old {
  color: $old-color;
}

.text-pass {
  color: $pass-color;
}

.text-old-light {
  color: $pass-color;
}

.bg-fuel {
  background: $fuel-color;
}

.bg-fuel-light {
  background: $fuel-back-light;
}

.bg-old {
  background: $old-color;
}

.bg-old-light {
  background: $pass-color;
}

.progress-group-append {
  flex: 0 0 180px;
  padding-left: 1rem;
  align-self: center;
}

.circle-legend {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.score-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
  -webkit-clip-path: inset(0 0 -1000px 0);
  clip-path: inset(0 0 -1000px 0);
  border-radius: 0.25rem;
}

.campagne-square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}

.block-ui-container {
  z-index: 1000;
}

.block-ui-message {
  // font-weight: 600;
  font-size: 16px;
  z-index: 1011;
}

.main-loader .block-ui-message-container,
.inner-loader .block-ui-message-container {
  top: 40vh;
}

.login-loader .block-ui-message-container {
  top: 50%;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 1;
  // filter: alpha(opacity=50);
  background-color: #ffffff;
}

.inner-loader .block-ui-overlay {
  opacity: 0.6;
  min-height: 70vh;
  // filter: alpha(opacity=50);
}

.popover.popover-columns {
  min-width: 400px !important;
}

.popover.popover-filter {
  min-width: 450px !important;
}

.sidebar .nav-link.active .nav-icon {
  color: $pass-color;
}

.sidebar .nav-link .nav-icon {
  font-size: 1rem;
}

.sidebar .nav-link.active:hover .nav-icon,
.sidebar .old-bo-link .nav-icon {
  color: #fff;
}

.react-datepicker-wrapper {
  display: block !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #d1d4d7;
}

.c-pointer {
  cursor: pointer !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.multiselect__indicator-separator {
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}

.multiselect__dropdown-indicator {
  padding: 3px !important;
}

.multiselect__control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multiselect__control--is-focused {
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0 !important;
  color: #3e515b !important;
  background-color: #fff !important;
  border-color: #8ad4ee !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
}

#progressbar {
  padding: 0;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
  text-align: center;
}

.modal-full .modal-content {
  width: 100vw;
  height: 100vh;
  border: 0 !important;
  border-radius: 0 !important;
}

.modal-full.modal-dialog {
  margin: 0;
  width: 100vw;
  height: 90vh;
}

.camp-choice-icon {
  color: $fuel-color;
}

.div-camp-choice {
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  background: $old-color;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.mobile-preview {
  width: 100%;
  height: 570px;
  padding: 40px 20px 70px;
  border-radius: 20px;
  margin: 0 auto;
  border: 2px solid $old-color;
}

.mobile-preview:before {
  width: 70px;
  position: absolute;
  height: 5px;
  margin-left: -50px;
  border: 1px solid $old-color;
  border-radius: 5px;
  top: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview:after {
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: -25px;
  border: 1px solid $old-color;
  border-radius: 100%;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview .mobile-wrapper iframe {
  height: 100%;
  width: 99.85%;
  border: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.mobile-preview .mobile-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid $old-color;
}

.desktop-header {
  height: 25px;
  position: relative;
  border-width: 2px 2px 0px 2px;
  border-color: $old-color;
  border-style: solid;
}

.desktop-header:before {
  display: block;
  position: absolute;
  content: "";
  top: 0.65em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #fb5, 3em 0 0 2px #9b3;
}

.desktop-wrapper {
  border-color: $old-color;
  border-width: 1px 2px 2px 2px;
  border-style: solid;
  min-height: 60vh;
}

.desktop-wrapper iframe {
  height: 100%;
  width: 100%;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.progress-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 55px;
  z-index: 10;
  background-color: #f8f9fa;
  transition: left 0.25s, width 0.25s;
}

@media (min-width: 992px) {
  .sidebar-show.sidebar-fixed .progress-container {
    left: 225px;
  }
}

#progressbar .progress-item-circle {
  width: 20px;
  height: 20px;
  line-height: 35px;
  display: block;
  font-size: 15px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  padding: 2px;
  z-index: 2;
}

#progressbar .active .progress-item-circle {
  background: #fd7e13;
}

#progressbar .passed .progress-item-circle {
  background: $fuel-back-light;
}

#progressbar li:not(:last-child):after {
  content: "";
  width: 100%;
  height: 3px;
  background: lightgray;
  position: absolute;
  left: 50%;
  top: 9px;
  z-index: -1;
}

// #progressbar li.active:after {
//     background: $fuel-back;
// }

#progressbar li.passed:after {
  background: $fuel-back-light;
}

.btn-old {
  color: #fff;
  background-color: $old-color;
  border-color: $old-color;
}

.btn-pass {
  color: #fff;
  background-color: $pass-color;
  border-color: $pass-color;
}

.btn-pass:hover,
.btn-pass:focus,
.btn-pass.focus {
  color: #fff;
  background-color: $pass-color-light;
  border-color: $pass-color-light;
}

.btn-outline-pass {
  color: $pass-color;
  background-color: transparent;
  background-image: none;
  border-color: $pass-color;
}

.btn-outline-pass:hover {
  color: #fff;
  background-color: $pass-color;
}

.btn-outline-old {
  color: $old-color;
  background-color: transparent;
  background-image: none;
  border-color: $old-color;
}

.btn-outline-old:hover {
  color: #fff;
  background-color: $old-color;
}

.btn-outline-fuel {
  color: $fuel-color;
  background-color: transparent;
  background-image: none;
  border-color: $fuel-color;
}

.btn-outline-fuel:hover {
  color: #fff;
  background-color: $fuel-color;
}

.btn-outline-fuel-light {
  color: $fuel-color-light;
  background-color: transparent;
  background-image: none;
  border-color: $fuel-color-light;
}

.btn-outline-fuel-light:hover {
  color: #fff;
  background-color: $fuel-color-light;
}

.btn-outline-old-light {
  color: $old-color-light;
  background-color: transparent;
  background-image: none;
  border-color: $old-color-light;
}

.btn-outline-old-light:hover {
  background-color: $old-color;
}

.border-old {
  border-color: $old-color !important;
}

.border-old-light {
  border-color: $old-color-light !important;
}

.border-old-grey {
  border-color: #9fa1a3 !important;
}

.btn-old:hover,
.btn-old:focus,
.btn-old.focus {
  color: #fff;
  background-color: $old-color-light;
  border-color: $old-color-light;
}

.add-filter {
  transition: 0.2s all ease-in-out;
  border: 1px solid #d1d4d7;
  cursor: pointer;
}

.add-filter:hover {
  border: 2px solid $old-color-light;
}

.pointer {
  cursor: pointer;
}

.abc-checkbox.abc-checkbox-fuel input[type="checkbox"]:checked + label::after,
.abc-checkbox.abc-checkbox-fuel input[type="radio"]:checked + label::after {
  font-family: "fontello" !important;
  content: "\e800" !important;
  color: #fff;
}

.abc-checkbox-fuel input[type="checkbox"]:checked + label::before,
.abc-checkbox-fuel input[type="radio"]:checked + label::before {
  background-color: $fuel-back;
  border-color: $fuel-back;
}

.abc-checkbox-fuel-light input[type="checkbox"]:checked + label::before,
.abc-checkbox-fuel-light input[type="radio"]:checked + label::before {
  background-color: $fuel-back-light;
  border-color: $fuel-back-light;
}

.abc-checkbox.abc-checkbox-old input[type="checkbox"]:checked + label::after,
.abc-checkbox.abc-checkbox-old input[type="radio"]:checked + label::after {
  font-family: "fontello" !important;
  content: "\e800" !important;
  color: #fff;
}

.abc-checkbox-old-light input[type="checkbox"]:checked + label::before,
.abc-checkbox-old-light input[type="radio"]:checked + label::before {
  background-color: $old-color-light;
  border-color: $old-color-light;
}

.abc-checkbox-old input[type="checkbox"]:checked + label::before,
.abc-checkbox-old input[type="radio"]:checked + label::before {
  background-color: $old-color;
  border-color: $old-color;
}

.abc-checkbox.checkbox-sm input[type="checkbox"]:checked + label::after,
.abc-checkbox.checkbox-sm input[type="radio"]:checked + label::after {
  font-family: "fontello" !important;
  content: "\e800" !important;
  color: #fff;
  font-size: 9px;
}

.abc-checkbox.checkbox-sm label::before {
  width: 14px;
  height: 14px;
  top: 2px;
  left: 0;
}

.abc-checkbox.checkbox-sm label::after {
  width: 14px;
  height: 14px;
  left: 0px;
  top: 1px;
}

.font-sm {
  font-size: 0.8rem !important;
}

.popover {
  z-index: 1050;
  border: #808080;
  border: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.62);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #9fa1a3;
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ebedef;
}

.popover-header {
  background-color: #ebedef;
  border-bottom-color: #ebedef;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.close-popover {
  position: absolute;
  right: 18px;
  padding: 2px 7px;
}

.bg-popover {
  background: #ebedef;
}

.btn-action {
  padding: 1px 5px;
}

.btn-dest-card {
  padding: 0.19rem 0.5rem;
}

.mobile-content {
  width: 370px;
  position: relative;
  transition: 0.3s width ease-in-out; // width: auto;
}

.mobile-preview-samsung:before {
  width: 70px;
  position: absolute;
  height: 5px;
  margin-left: -50px;
  border: 1px solid #46546c;
  border-radius: 5px;
  top: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview-samsung:after {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-left: -25px;
  border: 1px solid #46546c;
  border-radius: 100%;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
}

.mobile-preview-samsung {
  width: 100%;
  height: 600px;
  padding: 12px 10px 12px;
  border-radius: 36px;
  margin: 0 auto;
  border: 2px solid #46546c;
}

.mobile-preview-samsung .mobile-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 23px;
  border: 1px solid #46546c;
}

.mobile-preview-samsung .mobile-wrapper iframe {
  height: 100%;
  width: 99.85%;
  border: 0px;
  border-radius: 23px;
  overflow: hidden;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.btn-edit-modele {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 19px;
}

.no-preview {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: auto;
}

.sms .mobile-preview-samsung .mobile-wrapper {
  padding: 15px 0px;
}

.sms .mobile-content {
  width: 315px !important;
}

.sms-mention__input {
  overflow-y: auto !important;
  padding: 15px;
  font-size: 16px !important;
  border-radius: 0.25rem;
}

.sms-mention__input:focus {
  border-color: #8ad4ee !important;
  outline: 0 !important;
  box-shadow: inset 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
}

.sms-mention__control {
  min-height: 300px;
}

.sms-mention__suggestions {
  border: 1px solid #aaaaaa;
  border-radius: 0.25rem;
  box-shadow: 0 4px 5px 0 rgba(60, 75, 100, 0.14),
    0 1px 10px 0 rgba(60, 75, 100, 0.12), 0 2px 4px -1px rgba(60, 75, 100, 0.2);
}

.sms-mention__suggestions__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.25rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: #4f5d73;
}

// .sms-mention__suggestions__item:hover {
//     text-decoration: none;
//     color: #455164;
//     background-color: #ebedef;
// }

.sms-mention__suggestions__item--focused {
  text-decoration: none;
  color: #455164;
  background-color: #ebedef;
}

.preview-sms-area {
  white-space: pre-line;
  line-height: 18px;
}

.sms-mention__suggestions {
  margin-top: 40px !important;
}

.sms-mention__highlighter {
  padding: 15px;
}

.sms-mention__highlighter strong,
.sms-mention__highlighter__substring {
  font-size: 16px;
}

.sms-mention__highlighter strong {
  position: relative;
  z-index: 6;
  color: #321fdb;
  background-color: none !important;
  text-decoration: underline;
  pointer-events: none;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
}

.payment-mode {
  height: 35px;
}

.border-envoi-selected {
  border-color: #2eb85c !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.is-invalid .form-control__control {
  border-color: #f86c6b !important;
  height: calc(1.5em + 0.75rem + 2px);
}

.react-select.is-invalid ~ .invalid-feedback,
.react-datepicker-wrapper ~ .invalid-feedback {
  display: block;
}

.form-control__control {
  cursor: pointer !important;
  height: 36px;
}

.form-control__control.form-control__control--is-focused {
  border-color: #8ad4ee !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
}

// .is-invalid .form-control-small__control {
//     border-color: #f86c6b !important;
//     height: calc(1.5em + 0.75rem + 2px);
// }

// .react-select.is-invalid~.invalid-feedback,
// .react-datepicker-wrapper~.invalid-feedback {
//     display: block;
// }

// .form-control-small__control {
//     cursor: pointer !important;
//     max-height: 25px !important;
// }

// .form-control-small__control.form-control-small__control--is-focused {

//     border-color: #8ad4ee !important;
//     outline: 0 !important;
//     box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
// }

.sms-mention__suggestions__item--focused {
  text-decoration: none;
  color: #fff;
  background-color: $fuel-back-light;
}

.ticket {
  position: relative;
  box-sizing: border-box;
  width: 300px;
  height: 450px;
  padding: 20px;
  border-radius: 10px;
  background: #ebeef1;
  box-shadow: 2px 2px 15px 0px #9fa1a3;
}

.ticket:before,
.ticket:after {
  content: "";
  position: absolute;
  left: 5px;
  height: 6px;
  width: 290px;
  background: #ebeef1;
}

.ticket:before {
  top: -5px;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      #ebeef1 50%,
      #ebeef1 100%
    ) -7px -8px/16px 16px repeat-x;
}

.ticket:after {
  bottom: -5px;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      #ebeef1 50%,
      #ebeef1 100%
    ) -7px -2px/16px 16px repeat-x;
}

.ticket__content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 6px solid #d8d8d8;
}

.ticket-label {
  font-size: 1.5rem;
  font-weight: 600;
}

.ticket-value {
  font-size: 1.2rem;
  font-weight: 600;
}

legend {
  font-weight: 600;
  font-size: 1.2rem;
  z-index: 10000;
}

.recap-title {
  margin-bottom: 16px;
}

tr.selected {
  background: #f3ddca !important;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  border-radius: 0.25rem;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled):hover,
.btn-outline-white:not(:disabled):not(.disabled).active {
  color: #151b1e;
  background-color: #fff;
  border-color: #fff;
}

.h-0 {
  height: 0;
}

.dropdown-menu {
  z-index: 1000000 !important;
}

.has-badge[data-count]:after {
  position: absolute;
  left: 5px;
  top: -5px;
  content: attr(data-count);
  font-size: 80%;
  padding: 0.4em 0.3em;
  border-radius: 100px;
  line-height: 0.75em;
  color: white;
  background: #7288ad;
  text-align: center;
  min-width: 1em;
  font-weight: bold;
}

.action-button {
  position: fixed;
  right: 15px;
  bottom: 0px;
  // max-width: 400px;
  transition: height 250ms ease-in-out;
}

.btn-sm-flex-h {
  max-height: 32px;
}

.h-editor {
  min-height: 100px;
}

.card-img-top {
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

.fileContainer .uploadPictureContainer {
  width: 100% !important;
}

.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.fileContainer p.fileLabel {
  font-size: 12px;
  margin: 8px 0 4px;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #e55353;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  width: 20px;
  height: 20px;
}

.btn-circle {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-nav .simplebar-scrollbar:before {
  background: #ddd;
}

.img-welcome {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.avatar-first-letter-big {
  border-radius: 50%;
  padding: 0.5rem;
  width: 80px;
  height: 80px;
  align-items: center;
  // border: 1px solid;
  display: flex;
  color: #ffffff;
  font-size: 3rem;
  justify-content: center;
  background: #9fa1a3;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.avatar-first-letter-medium {
  border-radius: 50%;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  align-items: center;
  // border: 1px solid;
  display: flex;
  color: #ffffff;
  font-size: 1.5rem;
  justify-content: center;
  background: #9fa1a3;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.profile-header {
  background: #3c4b64;
  // height: 150px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.profile-name {
  color: #ffffff;
  font-size: 1.5rem;
}

.cke_combo__infos_client .cke_combo_text {
  width: 120px !important;
}

.cke_combopanel__infos_client {
  min-width: 280px !important;
  min-height: 230px !important;
}

.infos-coupons {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.c-app {
  min-height: 100vh;
  display: flex;
}

.border-top-only {
  border: none;
  border-top: 1px solid #d1d4d7 !important;
}

.tabs-form .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $pass-color;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid !important;
  font-size: 15px;
  font-weight: bold;
}

.tabs-form .nav-link {
  border: none !important;
  // border-bottom: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: $fuel-color;
  font-size: 15px;
  font-weight: 600;
}

.nav-tabs .nav-link:hover {
  border: none;
  color: $old-color-light !important;
}

.nav-tabs .nav-link:not(.active) {
  margin-bottom: 3px;
}

.tabs-form .tabs-form a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.tabs-form.no-child .tab-content,
.tab-pane {
  padding: 0 !important;
}

.tab-content legend {
  font-size: 15px;
  color: #4c75b5;
}

.tab-content {
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.overlay-warning-tpe {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

.form-control-small__indicator-separator {
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}

.form-control-small__dropdown-indicator {
  padding: 3px !important;
}

.form-control-small__control {
  display: block;
  width: 100%;
  min-height: 25px !important;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-small__control--is-focused {
  height: 25px !important;
  padding: 0 !important;
  color: #3e515b !important;
  background-color: #fff !important;
  border-color: #8ad4ee !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
}

.form-control-small__single-value {
  font-size: 12px !important;
  color: #768192 !important;
}

/* REACT SELECT*/

.async-select__control {
  min-height: 25px !important;
  font-size: 0.765625rem !important;
  border-color: #d8dbe0 !important;
}

.async-select__control--is-focused {
  color: #3e515b !important;
  background-color: #fff !important;
  border-color: #8ad4ee !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;
}

.is-invalid .async-select__control {
  border-color: #e55353 !important;
}

.async-select__value-container {
  min-height: 25px !important;
  font-size: 0.765625rem !important;
  padding: 0 8px !important;
}

.async-select__placeholder {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.async-select__indicator {
  padding: 0 !important;
}

.async-select__indicator-separator {
  margin: 0 !important;
  height: 25px !important;
}

.async-select__option {
  padding: 2px 7px !important;
}

.async-select__single-value {
  color: #768192 !important;
}

.async-select__control--is-disabled {
  background-color: #ebeef1 !important;
  border-color: #d8dbe0 !important;
}

/* END REACT SELECT */

.font-xs {
  font-size: 11px !important;
}

.border-2 {
  border-width: 2px !important;
}

.no-data-color {
  color: rgba(118, 129, 146, 0.7);
}

.is-ticket-annule-in-progress {
  background-color: pink;
  float: left;
  padding: 0.05rem 0.2rem;
  border-radius: 0.15rem;
}

.is-ticket-annule {
  background-color: #0f75bc;
  color: white;
  float: left;
  padding: 0.05rem 0.2rem;
  border-radius: 0.15rem;
}

.is-ticket-annule-2 {
  background-color: red;
  color: white;
  float: left;
  padding: 0.05rem 0.2rem;
  border-radius: 0.15rem;
}

.bg-is-done {
  background-color: #8189f7 !important;
}

.back-bon {
  cursor: pointer;
  text-align: center;
  color: #151b1e;
  // float: left;
  // font-weight: 700;
  padding: 0.05rem 0.2rem;
  border-radius: 0.15rem;
}

.bg-light {
  background-color: #ddd !important;
}
