@font-face {
  font-family: 'fontello';
  src: url('../../font/fontello.eot?86326227');
  src: url('../../font/fontello.eot?86326227#iefix') format('embedded-opentype'),
    url('../../font/fontello.woff2?86326227') format('woff2'),
    url('../../font/fontello.woff?86326227') format('woff'),
    url('../../font/fontello.ttf?86326227') format('truetype'),
    url('../../font/fontello.svg?86326227#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}


.abc-checkbox input[type="checkbox"]:checked+label::after,
.abc-checkbox input[type="radio"]:checked+label::after {
  font-family: "fontello" !important;
  content: "\e800" !important;
}

.abc-checkbox-primary input[type="checkbox"]:checked+label::before,
.abc-checkbox-primary input[type="radio"]:checked+label::before {
  background-color: $pass-color;
  border-color: $pass-color;
  outline: none !important;
}

