

.btn:not([class*=link]):not([class*=outline]) {
    border: 0;
    box-shadow: 0 1px 1px 0 rgba(60, 75, 100, .14), 0 2px 1px -1px rgba(60, 75, 100, .12), 0 1px 3px 0 rgba(60, 75, 100, .2);
}

.bg-fid {
    background: $fuel-color;
    color: #ffffff;
}

.bg-old {
    background: $old-color;
    color: #ffffff;
}

.callout.callout-old {
    border-left-color: $old-color;
}

.callout.callout-fid {
    border-left-color: $fuel-color;
}

.progress {
    border-radius: .25rem;
    background-color: #d1d4d7;
}





/*
 *  Usage:
 *
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
 *
 */
.sk-wave {
    margin: 40px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.sk-wave .sk-rect {
    background-color: $pass-color;
    margin-right: 3px;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-wave .sk-rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-wave .sk-rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-waveStretchDelay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@keyframes sk-waveStretchDelay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

.center-h {
    justify-content: center !important;
}

.center-v {
    align-items: center !important;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 3px solid $fuel-color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $fuel-color transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.card.bg-olive .card-header {
    background-color: #388c66;
    border-color: #2c7152;
}


.card.bg-teal .card-header {
    background-color: #1fb387;
    border-color: #138c68;
}

.card.bg-orange .card-header {
    background-color: #dc6e13;
    border-color: #a2500c;
}

.card.bg-navy .card-header {
    background-color: #00172f;
    border-color: #000102;
}

.card.bg-navy .card-header {
    background-color: #00172f;
    border-color: #000102;
}

.card.bg-secondary .card-header {
    background-color: #62696f;
    border-color: #afb4b9;
}

.card.bg-lightblue .card-header {
    background-color: #377fa9;
    border-color: #2d6d92;
}